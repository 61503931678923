import MaterialsApiService from "./api/MaterialsApiService";

export const CreateAlert = async (payload, userToken) => {
    try {
        const response = await MaterialsApiService.post('/warnings', payload, {
            headers: {
                Authorization: `Bearer ${userToken}`,
                'Content-Type': 'multipart/form-data'
            }
        })
        return response.data;
    } catch (error) {
        console.log("Erro ao Adicionar Alerta: ", error)
        throw error;
    }
}

export const UpdateAlertContent = async (payload, userToken) => {
    try {
        const id = payload.id
        const response = await MaterialsApiService.post(`/warnings/${id}/update`, payload, {
            headers: {
                Authorization: 'Bearer ' + userToken,
                'Content-Type': 'multipart/form-data'
            }
        })

        return response.data;
    } catch (error) {
        console.log("Erro ao atualizar Alerta: ", error)

        throw error
    }
}

export const DeleteAlert = async (payload, userToken) => {
    try {
        const id = payload.id
        const response = await MaterialsApiService.delete(`/warnings/${id}`, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        })

        return response.data
    } catch (error) {
        console.log("Erro ao deletar Alerta", error)

        throw error
    }
}

export const GetAlert = async (payload, userToken) => {

    let url = `/warnings?company_id=${payload.company_id}`

    if (payload.title) {
        url = url + `&search=${payload.title}`
    }

    if (payload.status) {
        url = url + `$status=${payload.status}`
    }

    try {
        const response = await MaterialsApiService.get(url, {
            headers: {
                Authorization: `Bearer ${userToken}`
            }
        })

        return response.data
    } catch (error) {
        console.log("Erro ao requisitar alertas", error)

        throw error
    }
}

export default {
    CreateAlert,
    UpdateAlertContent,
    DeleteAlert,
    GetAlert
}