<template>
  <Transition name="fade">
      <button class="btn btn-primary ai-button" @click="scrollToTop">
          <i class="bi bi-chevron-double-up"></i>
      </button>
  </Transition>
</template>

<script>
export default {
    name: 'ScrollToTopButton',
    props: {
        showButton: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
}
</script>

<style scoped>
.btn {
    position: fixed;
    right: 1rem;
    bottom: 2rem;
    border: none;
    background-color: var(--blue-5);
    justify-content: flex-end;
    margin-bottom: 20px;
    color: white;

    &:hover {
        background-color: var(--blue-0);
    }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease; /* Controla a duração e suavidade */
}

/* Classe inicial do fade-in (opacidade 0) */
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

/* Classe final do fade-out (opacidade 1) */
.fade-enter-to, .fade-leave-from {
  opacity: 1;
}
</style>