<template>
  <button
      role="button"
      aria-label="Abrir Menu Lateral"
      @click="handleSideBar"
      v-if="isSmallScreen && !sideBarActive" class="menu-icon position-absolute rounded-2 z-3"
  >
    <i class="bi bi-list"></i>
  </button>

  <transition :name="isSmallScreen ? 'slide' : ''">
    <aside
        role="list"
        aria-label="Menu Lateral"
        v-if="sideBarActive"
        class="side-bar z-3"
        :style="{ width: isSmallScreen ? '240px' : sideBarCollapsed ? '240px' : '70px',
      position: isSmallScreen ? 'fixed' : 'relative', backgroundColor: isSmallScreen ? '#E3F0F9' : sideBarCollapsed ? '#E3F0F9' : '#f5fafd'
      }"
    >
      <div
          class="side-bar-content"
          :style="{ width: isSmallScreen ? '240px' : sideBarCollapsed ? '240px' : '70px',
      position: isSmallScreen ? 'relative' : 'fixed'
      }"
      >
        <div class="side-bar-header position-relative d-flex align-items-center justify-content-center">
          <button
              @click="handleSideBar"
              :style="{ transform: showContent || ( isSmallScreen && sideBarActive ) ? '' : 'rotate(180deg)'}"
              role="button"
              aria-label="Fechar ou Abrir menu Lateral"
              class="position-absolute return-button border-0 bg-transparent active-route"
          >
            <transition name="fade">
              <i class="bi bi-arrow-left-square fs-5"></i>
            </transition>
          </button>
          <div class="image-container">
            <transition name="fade">
              <img v-show="showImage" :src="this.getConsumerSettings.settings.photo" alt="Logo Empresa"/>
            </transition>
          </div>
          <ul class="m-0 p-0 position-absolute"
               style="bottom: 20px"
          >
            <li
                role="button"
                aria-label="Voltar para Menu Inicial"
                @click.stop="option('home')"
                class="router-option active-route d-flex align-items-center"
                :style="{ width: showContent ? '150px' : '22px',
                color:
                ((getType === 'student' || getType === 'tutor' || getType === 'teacher') && route.name === 'My Disciplines' )
                || ((getType === 'admin') && route.name === 'Home')
                || (route.name === 'Disciplines')
                ? '#FA5540' : '#787486'}"
            >
              <i class="bi bi-house fs-5 me-4"></i>
              <transition name="fade">
                <p class="m-0 fs-6 p-0" v-show="showContent">Início</p>
              </transition>
            </li>
          </ul>
        </div>


        <div class="side-bar-body d-flex justify-content-center align-items-start">
          <ul class="m-0 p-0">
            <li
                role="button"
                aria-label="Ir para tela de usuarios"
                @click.stop="option('users')"
                v-if="getType === 'admin' || getType === 'manager' && !disciplinesViews.includes(route.name)"
                class="router-option active-route my-4 d-flex align-items-center"
                :style="{ width: showContent ? '150px' : '22px', color: route.name === 'Users' ? '#FA5540' : '#787486' }"
            >
              <i class="bi bi-people fs-5 me-4"></i>
              <transition name="fade">
                <p class="m-0 fs-6 p-0" v-show="showContent">Usuarios</p>
              </transition>
            </li>
            <li
                role="button"
                aria-label="Ir para tela de empresas"
                @click.stop="option('companies')"
                v-if="getType === 'admin'"
                class="router-option active-route my-4 d-flex align-items-center"
                :style="{ width: showContent ? '150px' : '22px', color: route.name === 'Companies' ? '#FA5540' : '#787486' }"
            >
              <i class="bi bi bi-building fs-5 me-4"></i>
              <transition name="fade">
                <p class="m-0 fs-6 p-0" v-show="showContent">Empresas</p>
              </transition>
            </li>
            <li
                role="button"
                aria-label="Ir para tela de Turma"
                @click.stop="option('class')"
                v-if="disciplinesViews.includes(route.name)"
                class="router-option active-route my-4 d-flex align-items-center"
                :style="{ width: showContent ? '150px' : '22px', color: route.name === 'Discipline Class' ? '#FA5540' : '#787486' }"
            >
              <i class="bi bi-person-workspace fs-5 me-4"></i>
              <transition name="fade">
                <p class="m-0 fs-6 p-0" v-show="showContent">Turma</p>
              </transition>
            </li>
            <li
                role="button"
                aria-label="Ir para a tela de Notas"
                @click.stop="option('grades')"
                v-if="disciplinesViews.includes(route.name)"
                class="router-option active-route my-4 d-flex align-items-center"
                :style="{ width: showContent ? '150px' : '22px', color: route.name === 'Discipline Grades' ? '#FA5540' : '#787486' }"
            >
              <i class="bi bi-person-lines-fill fs-5 me-4"></i>
              <transition name="fade">
                <p class="m-0 fs-6 p-0" v-show="showContent">Notas</p>
              </transition>
            </li>
            <li
                role="button"
                aria-label="Ir para a tela de Calendário"
                @click.stop="option('calendar')"
                v-if="getType === 'student'"
                class="router-option active-route my-4 d-flex align-items-center"
                :style="{ width: showContent ? '150px' : '22px', color: route.name === 'Calendar View' ? '#FA5540' : '#787486' }"
            >
              <i class="bi bi-calendar fs-5 me-4"></i>
              <transition name="fade">
                <p class="m-0 fs-6 p-0" v-show="showContent">Calendário</p>
              </transition>
            </li>
            <li
                role="button"
                aria-label="Ir para a tela de Calendário"
                @click.stop="option('warning')"
                v-if="warningRoutePermission.includes(getType)"
                class="router-option active-route my-4 d-flex align-items-center"
                :style="{ width: showContent ? '150px' : '22px', color: route.name === 'Warning View' ? '#FA5540' : '#787486' }"
            >
              <i class="bi bi-bell fs-5 me-4"></i>
              <transition name="fade">
                <p class="m-0 fs-6 p-0" v-show="showContent">Avisos</p>
              </transition>
            </li>
            <li
                role="button"
                aria-label="Entrar em contato com o Tutor"
                @click.stop="option('chat')"
                v-if="getType === 'student' && this.getConsumerSettings.settings.tutoring_phone_number_enabled"
                class="router-option active-route my-4 d-flex align-items-center"
                :style="{ width: showContent ? '150px' : '22px' }"
            >
              <i class="bi bi-chat fs-5 me-4"></i>
              <transition name="fade">
                <p class="m-0 fs-6 p-0" v-show="showContent">Falar com Tutor</p>
              </transition>
            </li>
            <li
                role="button"
                aria-label="Funcionalidade em Desenvolvimento"
                v-if="showNewFeatures.includes(getType) && !disciplinesViews.includes(route.name)"
                @click.stop="warmUpMessage()"
                class="router-option disable-route my-4 d-flex align-items-center"
                :style="{ width: showContent ? '150px' : '22px' }"
            >
              <i class="bi bi-pencil fs-5 me-4"></i>
              <transition name="fade">
                <p class="m-0 fs-6 p-0" v-show="showContent">Produção</p>
              </transition>
            </li>
            <li
                role="button"
                aria-label="Funcionalidade em Desenvolvimento"
                v-if="showNewFeatures.includes(getType) && !disciplinesViews.includes(route.name)"
                @click.stop="warmUpMessage()"
                class="router-option disable-route my-4 d-flex align-items-center"
                :style="{ width: showContent ? '150px' : '22px' }"
            >
              <i class="i bi-person-video fs-5 me-4"></i>
              <transition name="fade">
                <p class="m-0 fs-6 p-0" v-show="showContent">Tutores</p>
              </transition>
            </li>
            <li
                role="button"
                aria-label="Funcionalidade em Desenvolvimento"
                v-if="showNewFeatures.includes(getType) && !disciplinesViews.includes(route.name)"
                @click.stop="warmUpMessage()"
                class="router-option disable-route my-4 d-flex align-items-center"
                :style="{ width: showContent ? '150px' : '22px' }"
            >
              <i class="bi bi-laptop fs-5 me-4"></i>
              <transition name="fade">
                <p class="m-0 fs-6 p-0" v-show="showContent">Projetos</p>
              </transition>
            </li>
          </ul>

        </div>
        <ul class="m-0 p-0 side-bar-footer d-flex align-items-center justify-content-center">
          <li
              role="button"
              aria-label="Abrir menu de opções do Usuario"
              @click.stop="showContent ? handleDropUp() : handleSideBar()"
              class="router-option active-route my-4 d-flex align-items-center justify-content-between"
              :style="{ width: showContent ? '210px' : '22px', color: showDropUp  || configRoutes.includes(route.name) ? '#FA5540' : '#787486'}"
          >
            <i class="bi bi-people fs-5 "></i>
            <transition name="fade">
              <p class="m-0 fs-6 p-0" v-show="showContent">
                {{ getName.length > 12 ? getName.splice(0, 12) + '...' : getName }}
              </p>
            </transition>
            <transition name="fade">
              <i v-show="showContent"
                 class="bi bi-chevron-up"
                 :style="{ transition: 'transform 0.5s', transform: showDropUp ? 'rotate(180deg)' : 'rotate(360deg)' }"
              ></i>
            </transition>
          </li>

          <transition name="slide-up-down">
            <div
                @click.stop="handleDropUp"
                v-show="showDropUp && showContent"
                class="drop-up-container rounded-2 py-1 w-100 position-absolute"
                :style="{backgroundColor: isSmallScreen ? '#E3F0F9' : sideBarCollapsed ? '#E3F0F9' : '#f5fafd'}"
                style="bottom: 50px"
            >
              <ul class="mx-3 p-0 fs-6">
                <li
                    role="button"
                    aria-label="Sair do Sistema"
                    @click.stop="logout()"
                    class="m-2 d-flex justify-content-between active-route">
                  <p class="m-0">Sair</p> <i class="bi bi-box-arrow-right"></i>
                </li>
                <li
                    role="button"
                    aria-label="Ir para tela de Configurações"
                    @click="option('settings')"
                    v-if="getType === 'manager' || getType === 'client'"
                    class="m-2 d-flex justify-content-between active-route"
                    :style="{color: route.name === 'Update Consumer Settings' ? '#FA5540' : '#787486' }"
                >
                  <p class="m-0">Configurações</p> <i class="bi bi-gear"></i>
                </li>
                <li
                    role="button"
                    aria-label="Ir para tela de Alterar Senha"
                    @click="option('change-password')"
                    class="m-2 d-flex justify-content-between active-route"
                    :style="{color: route.name === 'Change Password' ? '#FA5540' : '#787486' }"
                >
                  <p class="m-0">Alterar senha</p> <i class="bi bi-key"></i>
                </li>
              </ul>
            </div>
          </transition>
        </ul>
      </div>
    </aside>
  </transition>
</template>

<script>
import router from '@/router'
import {mapGetters, useStore} from 'vuex';
import {toast} from "vue3-toastify";
import {useRoute} from "vue-router";

export default {
  name: 'SideBarComponent',
  data() {
    return {
      isSmallScreen: false,
      sideBarActive: false,
      sideBarCollapsed: false,
      showContent: false,
      showImage: false,
      showDropUp: false,
      route: useRoute(),
      store: useStore(),
      disciplinesViews: ['Discipline Class', 'Discipline Grades',
        'Discipline Material', 'Questionnaire Feedback',
        'Questionnaire Answers', 'Questionnaire Attempt',
        'Questionnaire'],
      showNewFeatures: ['manager', 'supervisor', 'tutor'],
      warningRoutePermission: ['manager', 'tutor', 'teacher', 'admin'],
      configRoutes: ['Update Consumer Settings', 'Change Password' ]
    }
  },

  computed: {
    ...mapGetters([
      'getToken',
      'getType',
      'getConsumerSettings',
      'getCompany',
      'getName'
    ]),
  },

  watch: {
    sideBarActive(newValue) {
      this.showContent = this.isSmallScreen && newValue
      if (this.showContent) {
        setTimeout(() => {
          this.showImage = true
        }, 300)

        return
      }
      this.showImage = false

    }, sideBarCollapsed(newValue) {
      this.showContent = !this.isSmallScreen && newValue
      if (this.showContent) {
        setTimeout(() => {
          this.showImage = true
        }, 300)

        return
      }
      this.showImage = false
    }
  },

  mounted() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.checkScreenSize);
  },

  methods: {

    warmUpMessage() {
      toast.info("Em desenvolvimento!", {
        position: 'top-right',
        theme: 'colored'
      });
    },

    checkScreenSize() {
      this.isSmallScreen = window.innerWidth <= 1280;
      if (this.isSmallScreen) {
        this.sideBarActive = false
        return
      }
      this.sideBarActive = true
    },

    handleSideBar() {
      if (this.isSmallScreen) {
        this.sideBarActive = !this.sideBarActive
        return
      }

      this.sideBarCollapsed = !this.sideBarCollapsed
    },

    handleDropUp() {
      this.showDropUp = !this.showDropUp
    },

    async logout() {
      console.log("Função Chamada")
      const payload = {
        userToken: this.getToken
      }

      try {
        await this.store.dispatch('logout', payload);

        router.push('/login');
      } catch (error) {
        console.log("Erro ao efetuar logout:", error)
      }
    },

    async option(option) {
      switch (option) {
        case 'home':
          if (this.getType == 'admin') {
            router.push('/home')
          } else if (this.getType == 'student' || this.getType == 'tutor' || this.getType == 'teacher') {
            router.push('/my/disciplines');
          } else {
            router.push('/disciplines');
          }
          break;
        case 'calendar':
          router.push('/calendar');
          break;
        case 'users':
          router.push('/users');
          break;
        case 'companies':
          router.push('/companies');
          break;
        case 'chat':
          window.open(`https://wa.me/+55${this.getConsumerSettings.settings.tutoring_phone_number}`, '_blank');
          break;
        case 'class':
          router.push(`/discipline/${this.$route.params.id}/class`);
          break;
        case 'grades':
          router.push(`/discipline/${this.$route.params.id}/grades`);
          break;
        case 'settings':
          router.push(`/consumers/settings`);
          break;
        case 'change-password':
          router.push(`/users/${this.getId}/change-password`);
          break;
        case 'warning':
          router.push('/notes')
          break;
      }
    },
  },
}

</script>

<style scoped>
.menu-icon {
  top: 15px;
  left: 15px;
  background-color: #f5fafd;
  border: 1px solid black;
}

.side-bar-content {
  height: 100vh;
  transition: 0.5s;
}

.side-bar {
  transition: 0.5s;
  min-height: 100dvh;
  border-right: 1px solid #DBDBDB;
  cursor: pointer;
}

.side-bar-header {
  height: 300px;
  border-bottom: 1px solid #DBDBDB;
}

.image-container {
  width: 100%;
  height: 150px;
}

.image-container img {
  width: 100%;
  height: 100%;
  padding: 50px;
}

.return-button {
  top: 15px;
  right: 20px;
}

.side-bar-body {
  height: calc(100vh - 360px);
  overflow-y: auto;
  overflow-x: hidden;
}

.router-option {
  transition: 0.5s;
}

.active-route {
  color: #787486;
}

.disable-route {
  color: #7874868F;
}

.side-bar-footer {
  height: 60px;
}

.drop-up-container {
  border: 1px solid #DBDBDB;
}

li {
  list-style-type: none;
}
</style>