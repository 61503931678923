import MaterialsApiService from "./api/MaterialsApiService";

export const createDisciplineTopic = async (payload, userToken) => {
    try {
        const response = await MaterialsApiService.post('/discipline-topics', {
            title: payload.title,
            description: payload.description,
            discipline_id: payload.disciplineId
        }, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao adicionar tópico', error);

        throw error;
    }
};

export const findDisciplineTopic = async (disciplineTopicId, userToken) => {
    try {
        const response = await MaterialsApiService.get('/discipline-topics/' + disciplineTopicId, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao consultar tópico', error);

        throw error;
    }
};

export const updateDisciplineTopic = async (payload, userToken) => {
    try {
        const response = await MaterialsApiService.put('/discipline-topics/' + payload.disciplineTopicId, {
            title: payload.title,
            description: payload.description,
            discipline_id: payload.disciplineId
        }, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao atualizar tópico', error);

        throw error;
    }
};

export const deleteDisciplineTopic = async (disciplineTopicId, userToken) => {
    try {
        const response = await MaterialsApiService.delete('/discipline-topics/' + disciplineTopicId, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao deletar tópico', error);

        throw error;
    }
};

export const getDisciplineTopics = async (payload, userToken) => {
    try {
        let url = '/discipline-topics?';

        if (payload.disciplineId) {
            url += `discipline_id=${payload.disciplineId}&`;
        }

        if (payload.search) {
            url += `search=${payload.search}&`;
        }

        const response = await MaterialsApiService.get(url.slice(0, -1), {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao consultar tópicos:', error);
        throw error;
    }
}

export const duplicateDisciplineTopic = async (payload, userToken) => {
    try {
        const response = await MaterialsApiService.post('/discipline-topics/' + payload.disciplineTopicId + '/duplicate', {
            discipline_id: payload.disciplineId
        }, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao duplicar tópico:', error);
        throw error;
    }
}

export const generateDisciplineTopic = async (payload, userToken) => {
    try {
        const response = await MaterialsApiService.post('/discipline-topics/generate', payload, {
            headers: {
                Authorization: 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao gerar tópicos:', error);
        throw error;
    }
}

export default {
    createDisciplineTopic,
    findDisciplineTopic,
    updateDisciplineTopic,
    deleteDisciplineTopic,
    getDisciplineTopics,
    duplicateDisciplineTopic,
    generateDisciplineTopic
}