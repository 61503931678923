
const state = {
    scrollToTopState: false
}

const mutations = {
    setScrollToTop: (state , payload) => {
        state.scrollToTopState = payload.scrollToTopState
    }
}

const actions = {
    setScrollToTop({commit}, payload) {
        commit('setScrollToTop', payload)
    }
}

const getters = {
    scrollToTopState: (state) => state.scrollToTopState
}

export default  {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}