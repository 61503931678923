<template>
  <div>
    <div
        v-if="loadingState"
        class="z-3 vw-100 vh-100 position-fixed d-flex align-items-center justify-content-center">
      <LoaderComponent/>
    </div>
    <div v-if="loadingView !== 'app'" class="d-flex">
      <SideBarComponent v-if="!shouldNotHaveSideBar.includes(route.name)"/>
      <div :class="!shouldNotHaveSideBar.includes(route.name) ? 'content-container' : 'full-size-container' ">
        <RouterView/>
      </div>
      <ScrollToTopButton v-show="scrollToTopState"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import 'vue3-toastify/dist/index.css';
import ScrollToTopButton from "@/components/buttons/ScrollToTopButton.vue";
import {useRoute} from "vue-router";
import SideBarComponent from "@/components/sidebars/SidebarComponent.vue";
import LoaderComponent from "@/components/loaders/LoaderComponent.vue";
import store from "@/store";

export default {

  data() {
    return {
      route: useRoute(),
      shouldNotHaveSideBar: ['Login', 'Forgot Password', 'Error', 'Not Found'],
      scrollViews: ['Disciplines', 'Questionnaire', 'My personalized disciplines', 'Create Quiz', 'Discipline Material', 'My Disciplines', 'Questionnaire Feedback', 'Quizzes', 'Update Quiz']
    }
  },

  components: {
    LoaderComponent,
    SideBarComponent,
    ScrollToTopButton,
  },

  computed: {
    ...mapGetters(["view", "isLoading", 'loadingState', 'loadingView']),
    ...mapGetters('scrollToTop', ['scrollToTopState'])
  },

  mounted() {
    this.$store.dispatch('scrollToTop/setScrollToTop', {
      scrollToTopState: false
    })
    store.dispatch('setLoadingState', {loadignState: false})
    window.addEventListener('scroll', this.handleScroll);
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {

    handleScroll() {
      const shouldButtonAppear = this.scrollViews.includes(this.route.name)

      if (window.scrollY > 100) {
        if (shouldButtonAppear) {
          this.$store.dispatch('scrollToTop/setScrollToTop', {
            scrollToTopState: true
          })
        }
      } else {
        this.$store.dispatch('scrollToTop/setScrollToTop', {
          scrollToTopState: false
        })
      }
    },

  }
};
</script>

<style scoped>
.full-size-container {
  width: 100%;
  height: 100%;
}

.content-container {
  width: 0px;
  flex-grow: 1;
  margin: 5% 2%;
}

@media (max-width: 1280px) {
  .content-container {
    margin-left: 2%;
  }

  .content-container {
    margin-top: 12%;
  }
}

</style>