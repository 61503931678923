import {toast} from "vue3-toastify";

const state = {
    notification: false
}

const mutations = {
    changeNotificationState (state) {
        state.notification = !state.notification
    }
}

const actions = {
    activeErrorNotification ({commit}, payload) {
        commit('changeNotificationState')
        toast.error(payload.text, {
            position: 'top-right',
            theme: 'colored'
        })
        commit('changeNotificationState')
    },
    activeSuccesNotification ({commit}, payload) {
        commit('changeNotificationState')
        toast.success(payload.text, {
            position: 'top-right',
            theme: 'colored'
        })
        commit('changeNotificationState')
    },
}

export default  {
    namespaced: true,
    state,
    mutations,
    actions,
}