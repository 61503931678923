<template>
  <div class="d-flex justify-content-center" >
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['isLoading', 'view'])
  }
};
</script>

<style scoped>
.spinner-border {
  color: var(--blue-5);
}
</style>