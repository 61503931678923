import DisciplineTopicsService from "@/services/DisciplineTopicsService";

export default {
    state() {
        return {
            disciplineTopic: {
                title: "",
                description: "",
                disciplineId: ""
            },
            disciplineTopicsData: "",
            endCycle: {
                reloadView: false,
                discipline: {}
            }
        }
    },

    getters: {
        getDisciplineTopicTitle: (state) => state.disciplineTopic.title,
        getDisciplineTopicDescription: (state) => state.disciplineTopic.description,
        getDisciplineTopicDisciplineId: (state) => state.disciplineTopic.disciplineId,
        endCycle: (state) => state.endCycle
    },

    mutations: {
        setDisciplineTopicData(state, data) {
            state.disciplineTopic.title = data.title;
            state.disciplineTopic.description = data.description;
            state.disciplineTopic.disciplineId = data.disciplineId;
        },
        resetDisciplineTopic(state) {
            state.disciplineTopic = {
                title: "",
                description: "",
                disciplineId: "",
            };
        },
        setDisciplineTopicsData(state, data) {
            state.disciplineTopicsData = data
        },

        setEndCycle(state, data) {
            state.endCycle = {...state.endCycle, reloadView: data.state, discipline: data.discipline};
        }
    },

    actions: {
        async createDisciplineTopic({ commit }, payload) {
            try {
                const response = await DisciplineTopicsService.createDisciplineTopic(payload.disciplineTopic, payload.userToken);

                commit('resetDisciplineTopic');

                return response.data;
            } catch (error) {
                console.error('Erro ao adicionar tópico:', error);

                throw new Error('Não foi possível adicionar tópico. Por favor, tente novamente.');
            }
        },

        async findDisciplineTopic({ commit }, payload) {
            try {
                const response = await DisciplineTopicsService.findDisciplineTopic(payload.disciplineTopicId, payload.userToken);

                commit('setDisciplineTopicData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao consultar tópico:', error);

                throw new Error('Não foi possível consultar tópico. Por favor, tente novamente.');
            }
        },

        async updateDisciplineTopic({ commit }, payload) {
            try {
                const response = await DisciplineTopicsService.updateDisciplineTopic(payload.disciplineTopic, payload.userToken);

                commit('setDisciplineTopicData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao atualizar tópico:', error);

                throw new Error('Não foi possível atualizar a tópico. Por favor, tente novamente.');
            }
        },

        async deleteDisciplineTopic({ commit }, payload) {
            try {
                const response = await DisciplineTopicsService.deleteDisciplineTopic(payload.disciplineTopicId, payload.userToken);

                commit('resetDisciplineTopic');

                return response.data;
            } catch (error) {
                console.error('Erro ao deletar tópico:', error);

                throw new Error('Não foi possível deletar a tópico. Por favor, tente novamente.');
            }
        },

        async getDisciplineTopics({ commit }, payload) {
            try {
                const response = await DisciplineTopicsService.getDisciplineTopics(payload.data, payload.userToken);

                commit('setDisciplineTopicsData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao consultar tópicos:', error);

                throw new Error('Não foi possível consultar as tópicos. Por favor, tente novamente.');
            }
        },

        async duplicateDisciplineTopic({ commit }, payload) {
            try {
                const response = await DisciplineTopicsService.duplicateDisciplineTopic(payload.data, payload.userToken);

                commit('resetDisciplineTopic');

                return response.data;
            } catch (error) {
                console.error('Erro ao duplicar tópico:', error);

                throw new Error('Não foi possível duplicar tópico. Por favor, tente novamente.');
            }
        },

        async generateDisciplineTopic({ commit }, payload) {
            try {
                const response = await DisciplineTopicsService.generateDisciplineTopic(payload.data, payload.userToken);

                commit('resetDisciplineTopic');
                return response.data;
            } catch (error) {
                console.error('Erro ao gerar tópico:', error);

                throw new Error('Não foi possível gerar tópicos. Por favor, tente novamente.');
            }
        },

        setEndCycle({commit}, payload) {
            commit('setEndCycle', payload)
        }
    }
}